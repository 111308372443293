import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import headsetLogo from '../img/headset-fill.svg';
const Footer = () => {
  return (
    <footer className='footer-section'>
    <Container className='custom-footer'>
      <Row>
      <Col md={3} style={{alignContent:"center"}} >
      
      <img
            src={headsetLogo}
            alt="Headset Logo"
            width="100"
            height="100"
            className="d-inline-block align-top"
          />
      
      </Col>
      <Col md={2}>
      <div>
      
      <ul className='menu'>
        <li><a href='/wie-wir-helfen'>Wie wir helfen</a></li>
        <li><a href='/wer-wir-sind'>Wer wir sind</a></li>
        <li><a href='/kontakt'>Kontakt</a></li>
        </ul>
        </div>
      </Col>
      <Col md={4}>
      <div className='widget_contact_list'>
        <div className='contact-item'>
        <p className='title'>Ruf uns an</p>
        <p className='content'> 030-629 30 20 50</p>
        </div>
        <div className='contact-item'>
        <p className='title'>Schreiben Sie uns eine E-Mail</p>
        <p className='content'>kontakt@smart-voice-connect.com</p>
        </div>
        </div>
      </Col>
      <Col md={3}>
      {/* */}
        <div>
        <ul className='menu'>
        <li><a href='/impressum'>Impressum</a></li>
        <li><a href='/datenschutz'>Datenschutz</a></li>

        </ul>
        
        </div>
      </Col>
      </Row>
      {/* <Row className='social-container'>
        <Col>
        <div className='text_align'>
      <p className='social_title'>Begleiten Sie uns</p>
      <span className='social-icons'><a className='fa fa-instagram border-icon rounded-icon' href='/#' ><FontAwesomeIcon icon={faInstagram} size='2xl' /></a> 
      <a className='fa fa-facebook border-icon rounded-icon' href='/#' ><FontAwesomeIcon icon={faFacebook} size='2xl' /></a>
      <a className='fa fa-paper-plane border-icon rounded-icon' href='/#' ><FontAwesomeIcon icon={faTelegram} size='2xl' /></a>
      <a className='fa fa-youtube-play border-icon rounded-icon' href='/#' ><FontAwesomeIcon icon={faYoutube} size='2xl' /></a>
      <a className='fa fa-twitter border-icon rounded-icon' href='/#' ><FontAwesomeIcon icon={faTwitter} size='2xl' /></a>
      
      </span>
        </div> </Col>
      </Row> */}
    </Container>
    <Container className='copyright-section'>
        <Row>
            <Col>
            <div className='text-center'>
            © <span ><span >2024</span></span> Copyright </div></Col>
        </Row>
    </Container>
    </footer>
  );
};

export default Footer;