import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Image1 from "../img/assisstance.jpg";
// import JornImg from "../img/Foto-Jorn-1.jpg";
// import ReinerImg from "../img/SharedScreenshot.jpg";

// const TeamMember = ({ name, position, image, description, link }) => (
//   <a href={link} className="team-member-container">
//     <div className="team-member">
//       <img src={image} alt={name} className="team-member-image" />
//       <h3>{name}</h3>
//       <p className="team-member-position">{position}</p>
//       <p>{description}</p>
//     </div>
//   </a>
// );
const WhoWeAre = () => {
  return (
    <div>
      <header id="header">
        <div className="intro-weare">
          <div className="overlay">
            <Container className="cont">
              <Row className="justify-content-md-center">
                <Col md="8" className="intern-intro-text">
                  <h1>Wer wir sind</h1>
                  <a
                    className="button"
                    href="/kontakt"
                    style={{ "--color": "#6eff3e" }}
                  >
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    kontaktiere uns
                  </a>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </header>
      <Container className="big_container">
        <Row className="custom-rows intern-rows">
          <Col md={6}>
            <h1>Smart Voice Connect: die etwas anderen Dienstleister</h1>
            <p>
            Mit ihrem Serviceangebot bereichern die Smart Voice Connect den Kundenservice kleiner und mittlerer Unternehmen um einen innovativen Baustein.
            </p>

            {/* <p>
              Die Smart Voice Connect sind ein Team aus erprobten Dienstleistern
              rund um Kundenservice und Büromanagement.
            </p> */}
          </Col>
          <Col md={6}>
            <div className="img-container">
              <Image src={Image1} fluid />
            </div>
          </Col>
        </Row>

        {/* <div className="container my-5">
          <h2 className="text-center mb-4">· Das Gründer-Team ·</h2>
          <div className="row justify-content-center">
            <div className="col-md-4">
              <TeamMember
                name="Jörn Eble"
                position="Gründer"
                image={JornImg}
                description="Jörns Mission verändert die Art und Weise, wie kleine und mittelständische Unternehmen risikolos wachsen und neue Märkte erschließen können."
                link="/gruender-joern-eble"
              />
            </div>
            <div className="col-md-4">
              <TeamMember
                name="Reiner Sieper"
                position="Gründer"
                image={ReinerImg}
                description="Reiner weiß aus über 20 Jahren Erfahrung, wie man Kundensupport richtig macht. Er ist der Experte für Prozessoptimierung und Personalmanagement."
                link="/gruender-reiner-sieper"
              />
            </div>
          </div>
        </div> */}
      </Container>
    </div>
  );
};

export default WhoWeAre;
